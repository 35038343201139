.view-all-movies-1 {
  margin-top: 0px !important;
}

.mr-2 {
  margin-right: 2px;
}
.mt-2 {
  margin-top: 10px !important;
}
.ticket-price p {
  color: #fff;
}
.font-18 {
  font-size: 18px !important;
}
.font-17 {
  font-size: 17px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 20px !important;
}
.mb-4 {
  margin-bottom: 30px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid rgba(192, 192, 192, 0.288);
}
.hadding-area .hadding-with-bg .cat-menu .tab-menu {
  text-align: center !important;
}
body {
  padding-right: 0px !important;
}

.slider-items {
  max-height: 480px !important;
  overflow: hidden !important;
}
.movie-item-contents {
  margin-bottom: 30px !important;
}

.custom-list-group .list-group-item {
  display: flex;
  align-items: start;
}
.custom-list-group .list-group-item > .badge {
  position: absolute;
  right: 0;
  top: 4px;
  padding: 3px 5px;
}

.w-200 {
  width: 200px;
  margin: 20px auto;
}
.custom-list-group .list-group-item input {
  margin-top: 4px;
}
.custom-list-group .list-group-item {
  line-height: 25px !important;
}
#navbar-menu {
  overflow-x: hidden;
}

.hadding-area h2 small {
  left: 0 !important;
  top: 15px;
  cursor: pointer;
}

.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.social-visit a {
  margin-bottom: 15px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
/* .hadding-area{
    margin-bottom: 60px;
} */
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mt-0 {
  margin-top: 0px !important;
}

.selebritie-list {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 0 !important;
}

.movie-list-item {
  flex-direction: row !important;
  align-items: center !important;
}
.selebritie-list img {
  width: 95% !important;
  padding: 15px !important;
  height: auto !important;
}
.detail-btn {
  padding: 7px 15px !important;
}
.category-movie {
  padding-top: 22px;
  padding-bottom: 35px !important;
}
.text-right {
  text-align: right;
}

/*.product-list .movie-item-contents{*/
/*    height: auto !important;*/
/*}*/
.footer-bg {
  padding-top: 55px !important;
  padding-bottom: 40px !important;
}
.copyright {
  background: #02070e !important;
}
.slider-content-bottom {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0),
    rgba(0, 0, 0, 0.66) 40%,
    rgba(0, 0, 0, 0.9)
  );
  width: 100%;
  opacity: 0.7;
}
.slider-content-bottom-2 {
  position: absolute;
  bottom: 0;
}
.media-text-body small {
  color: #fff;
}
.get-ticket-btn {
  white-space: normal !important;
}

.show-time-wrapper .col {
  flex: 1 0 0%;
  border-left: 1px solid #39353c;
}
.flex-wrap {
  flex-wrap: wrap;
}

.h-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}

.min-height-250 {
  min-height: 200px;
}

.w-auto {
  width: auto;
}

.mt-auto {
  margin-top: auto !important;
}

.flex-direction-column {
  flex-direction: column;
}
.min-calc-height {
  min-height: calc(100% - 100px);
}

.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.px-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.p-10 {
  padding: 10px;
}

.text-muted {
  color: #5a595b;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.py-2 {
  padding: 10px 0;
}

@media screen and (max-width: 1125px) {
  .time-select .time-select__item,
  .time-select .time-select__item,
  .time-select .time-select__item_6,
  .time-select__item_7 {
    line-height: 17px !important;
  }
  .transform-50 {
    transform: translateX(20%);
  }
}
@media screen and (max-width: 1081px) {
  .dec-review-meta ul li a {
    display: inline-flex !important;
  }
  .selebritie-list {
    margin-bottom: 0px !important;
  }
  .time-select .time-select__item,
  .time-select .time-select__item,
  .time-select .time-select__item_6,
  .time-select__item_7 {
    line-height: 17px !important;
  }
  .get-ticket-btn {
    white-space: normal !important;
  }
}
@media screen and (min-width: 1024px) {
  .time-select .time-select__item,
  .time-select .time-select__item,
  .time-select .time-select__item_6,
  .time-select__item_7 {
    width: 86px !important;
    line-height: 37px !important;
  }
}

@media (min-width: 991px) {
  .flex-wrap {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1128px) {
  .time-select .time-select__item,
  .time-select .time-select__item,
  .time-select .time-select__item_6,
  .time-select__item_7 {
    width: 100% !important;
    line-height: 37px !important;
  }

  .transform-50 {
    transform: translateX(0%);
  }
  /* .get-ticket-btn{
        width: 100%;
    }  */
  .showtime-heading {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 991px) {
  .time-select .time-select__item,
  .time-select .time-select__item,
  .time-select .time-select__item_6,
  .time-select__item_7 {
    width: auto !important;
    line-height: 37px !important;
  }
  .selebritie-list {
    display: flex !important;
    align-items: center !important;
  }
  .font-12 {
    font-size: 12px !important;
  }
}

@media (max-width: 991px) {
  .details-title > h2 {
    line-height: 25px;
  }

  .pb-2 {
    padding-bottom: 10px;
  }

  .location_pick_modal {
    font-size: 12px;
    font-weight: 500;
  }
  .sm-none {
    display: none;
  }
  .social-visit a.btn.btn-button {
    padding: 4px 10px;
    margin-bottom: 10px;
  }
  .cat-menu ul li a {
    margin-right: 6px;
  }
  .cat-menu ul li a span {
    font-size: 13px !important;
  }
  .seat-types .btn {
    margin-bottom: 0px;
    padding: 0;
    width: 17px;
    height: 18px;
  }
  .seat-types {
    margin-top: 28px !important;
  }
  .time-select.time-select__group .btn.btn-button.red-bg.button-1 {
    margin: 10px auto 20px !important;
  }

  .seat-types span {
    font-size: 12px !important;
  }
}

@media (max-width: 980px) {
  button.btn.btn-button.btn-border,
  a.btn.btn-button.btn-border,
  .btn.btn-button,
  a.btn.btn-button {
    border-radius: 4px !important;
  }
  .owl-stage {
    padding-left: 28px !important;
  }
  .hadding-area {
    padding-top: 0px !important;
  }

  .slider-content {
    margin-top: 15px;
  }

  .slider-content a.btn.btn-button {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .btn.btn-button,
  a.btn.btn-button {
    padding: 4px 10px !important;
    font-size: 12px !important;
  }

  .footer-bg {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .social-visit {
    padding: 4px 0;
  }
  .wrap-sticky {
    height: 60px !important;
  }

  /* .slider-content{
        text-align:center !important;
    } */
  .haddings {
    margin-bottom: 5px;
  }
  .wrap-sticky nav.navbar.bootsnav {
    top: 5px !important;
  }
  .cat-menu ul li a span {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  /* .get-ticket-btn{
        width: 200px;
    }  */

  .cat-menu ul li a {
    margin-right: 5px !important;
  }
  .cat-menu ul li.current span:after {
    height: 1.5px !important;
  }

  .category-slide:hover .owl-nav .owl-prev {
    display: none;
  }
  .category-slide:hover .owl-nav .owl-next {
    display: none;
  }
  .latest-news-section {
    padding-top: 20px !important;
  }
  .hadding-area {
    padding-bottom: 0px !important;
    margin-bottom: 25px !important;
  }
  .movie-item-contents {
    margin-bottom: 15px !important;
  }
  .hadding-area h2 {
    line-height: 25px;
    font-size: 15px;
  }

  .sm-mt-2 {
    margin-top: 20px !important;
  }
  .flat-icons span.flaticon-play-button:before {
    font-size: 35px;
  }
  .movie-item-title > a {
    font-size: 14px;
    line-height: 18px;
  }
  .item-cat-hover {
    margin-top: 2px !important;
  }
  .movie-item-content-buttom {
    margin-top: 0px;
  }
  .slider-content > h2 {
    font-size: 12px;
  }
  .social .attr-nav .styled-select {
    line-height: 30px;
    height: 30px;
  }
  .ft-widget h2 span {
    font-size: 12px;
  }
  .category-movie {
    padding-top: 22px;
    padding-bottom: 25px !important;
  }
  .slider-section .flaticon-send:before {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .selebritie-list {
    display: flex !important;
    align-items: center !important;
  }
  /* .haddings .row{
        display: block !important;
        text-align: center !important;
    } */
  /* .get-ticket-btn{
        width: 200px;
    }  */
  .dec-review-img {
    width: 55% !important;
    margin: 0 auto;
  }
  .owl-stage {
    padding-left: 20px !important;
  }
  .d-sm-block {
    display: inline-block !important;
  }
}
@media (max-width: 768px) {
  .view-all-movie {
    text-align: center !important;
  }
  .view-all-movies-1 {
    /* float:left !important; */
    text-align: center !important;
  }

  .movie_info_div {
    display: block !important;
  }

  .footer-col-1 {
    clear: both;
  }
  .footer-col-2 {
    clear: right;
  }
  .footer-col-3 {
    clear: right;
  }
  .footer-col-4 {
    clear: right;
  }

  .header-search .header-search-input {
    border-color: transparent !important;
  }
  .header-search .header-search-input.search-open {
    border: 1px solid #fff !important;
  }
  .ft-widget {
    min-height: 330px !important;
  }
}

@media (max-width: 680px) {
  .footer-col-1 {
    clear: both;
  }
  .footer-col-2 {
    clear: right;
  }
  .footer-col-3 {
    clear: both;
  }

  .ft-widget {
    min-height: auto !important;
  }
  .xs-width {
    width: 50%;
  }
}

@media (max-width: 668px) {
  .selebritie-img img {
    height: auto !important;
    width: 80% !important;
  }
  .hadding-area h2 small {
    top: 15px;
  }
}

@media (max-width: 550px) {
  .selebritie-img img {
    height: auto !important;
    width: 50% !important;
  }
  .show-time-wrapper .row.display-flex {
    display: block !important;
  }
  .selebritie-list {
    display: block !important;
  }
}
@media (max-width: 425px) {
  .hadding-area h2 small {
    top: 9px;
  }
  .dec-review-img {
    width: 64% !important;
    margin: 0 auto;
  }
  .navbar-collapse {
    overflow-x: hidden !important;
  }

  .cat-menu ul li {
    margin-right: 0px !important;
  }
  .selebritie-list {
    display: block !important;
    align-items: center !important;
  }
  .selebritie-img img {
    height: auto !important;
    width: 50% !important;
  }

  .haddings .row {
    display: block !important;
  }
  .view-all-movie {
    text-align: center !important;
  }
  .view-all-movies-1 {
    /* float:left !important; */
    text-align: center !important;
  }
  .social .attr-nav {
    width: 100% !important;
  }

  .wrap-sticky {
    height: 60px !important;
  }
  .social .attr-nav {
    margin: 0px;
    right: 0;
    float: none;
    display: initial;
  }
  .wrap-sticky nav.navbar.bootsnav {
    position: absolute;
    width: 100%;
    left: 0px !important;
    top: 20px !important;
    padding: 0 10px !important;
    top: 16px !important;
  }
  .header-search .header-search-input.search-open {
    width: 100% !important;
  }
  .navbar-header {
    float: right !important;
  }
  .mr-0 {
    margin-right: 0px !important;
  }
  .header-search .header-search-input {
    border-color: transparent !important;
  }
  .header-search .header-search-input.search-open {
    border: 1px solid #fff !important;
  }

  .navbar-toggle {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }
  .header-search {
    margin: -5px 12px !important;
  }
  .header-center {
    padding: 15px 0px !important;
  }
  /* .header-search .header-search-input, .header-search .header-search-input.search-open{
        height: 40px !important;
    } */

  .tab-menu {
    margin-bottom: 10px;
  }

  /* .category-slide .movie-item-contents {
        height: 235px;
    } */

  .seat-types {
    margin-top: 20px !important;
  }

  .hadding-area h2 small {
    padding: 10px 30px 10px 20px;
  }

  .seat-types {
    margin-top: 20px !important;
  }
  .view-all-movies-1 {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .xs-width {
    width: 100%;
  }
  .seat-types span {
    font-size: 11px !important;
    margin-right: 4px !important;
  }
  .seat-types span:last-child {
    margin-right: 0px !important;
  }
  .seat-types .btn {
    margin-bottom: 0px;
    padding: 0;
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 325px) {
  .xs-width {
    width: 100%;
  }
  .navbar-toggle {
    margin-top: -4px !important;
    margin-bottom: 0px !important;
  }
  .seat-types span {
    font-size: 10.5px !important;
    margin-right: 4px !important;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .ft-widget {
    min-height: auto !important;
  }
  .ft-widget {
    min-height: 364px;
    padding-bottom: 0;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 667px) {
  .slider-img {
    height: auto !important;
  }
}

@media (min-width: 991px) {
  .d-lg-none {
    display: none;
  }
  .showing-tab {
    margin-bottom: 20px;
  }
}
