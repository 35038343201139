.contact-details a {
  color: #b945ff;
}
#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.notfound .notfound-404 {
  height: 158px;
  line-height: 153px;
}

.notfound .notfound-404 h1 {
  font-family: "Josefin Sans", sans-serif;
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0;
  font-weight: 700;
  text-shadow: 2px 2px 0 #c9c9c9, -2px -2px 0 #c9c9c9;
}

.notfound .notfound-404 h1 > span {
  text-shadow: 2px 2px 0 #ffab00, -2px -2px 0 #ffab00, 0 0 8px #ff8700;
}

.notfound p {
  font-family: "Josefin Sans", sans-serif;
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 15px;
}

.notfound a {
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: 0 0;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #ffab00;
  border-color: #ffab00;
}

.contentHead {
  background: #1e272f;
  border-radius: 4px 4px 0 0;
  color: #fff;
  height: 40px;
  line-height: 34px;
  padding-left: 10px;
  text-shadow: 0 1px 2px #191a1b, 0 1px 0 #191a1b;
  white-space: nowrap;
}

.recipientcase h3 {
  color: #2d6892;
  font-size: 1em;
  font-weight: 700;
  line-height: 19px;
  margin: 0;
  padding: 7px;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #5f1a89;
}

.search-result-box {
  display: block;
  position: absolute;
  z-index: 100;
  width: 340px;
  border: 1px solid #5f1a89;
  background: #333;
  margin-top: 6.5%;
  border-radius: 5px;
  max-height: 350px;
  overflow-y: scroll;
  top: 45px;
  right: 5px;
}

.owl-nav.disabled {
  display: none;
}

.time-select__item_7 {
  background-color: #55c8ff;
  cursor: pointer;
  /* display: inline-block; */
  font-size: 12px;
  line-height: 37px;
  margin: 9px auto;
  padding: 0 11px;
  position: relative;
  z-index: 10;
  color: #1d1d1d;
  border-radius: 2px;
  width: 86px;
}

.show-time-wrapper.odd .time-select .time-select__item_7:before,
.time-select .time-select__item_7:before {
  background: #13151f;
  content: "";
  height: 12px;
  width: 12px;
  left: -7px;
  top: 13px;
  position: absolute;
  border-radius: 50%;
}

.show-time-wrapper.odd .time-select .time-select__item_7:after,
.time-select .time-select__item_7:after {
  background: #13151f;
  content: "";
  height: 12px;
  width: 12px;
  right: -7px;
  top: 13px;
  position: absolute;
  border-radius: 50%;
}

.show-time-wrapper.odd .time-select .time-select__item_7:after,
.show-time-wrapper.odd .time-select .time-select__item_7:before {
  background: #252629;
}

.search-movie-image {
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
}

.search-movie-image img {
  width: 100%;
}

.search-movie-name {
  min-height: 80px;
  max-height: 80px;
}

.search-movie-name a {
  line-height: 80px;
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  text-transform: capitalize;
}

.each_search_list {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
  padding: 10px 0;
}

/*.product-list .movie-item-contents {*/
/*height: 400px;*/
/*width: 290px*/
/*}*/
.category-slide .movie-item-contents {
  /* height: 301px; */
  overflow: hidden;
}

.hadding-area h2 span {
  font-size: 15px;
  font-weight: 400;
}

.hadding-area h2 small {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  position: relative;
  border: 2px solid #5f1a89;
  padding: 6px 30px 6px 20px;
  border-radius: 4px;
  left: 20px;
}

.hadding-area h2 small:after {
  color: #ffffff;
  content: "\f041";
  font-family: "FontAwesome";
  position: absolute;
  right: 11px;
  top: 10px;
}

.ticket_list_item_tabs {
  position: relative;
  z-index: 9;
  border: 2px solid #fff;
  text-align: center;
}

.ticket_list_item_tabs h4 {
  margin: 20px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.ticket_list_item .red-bg:active,
.ticket_list_item .red-bg:focus,
.ticket_list_item .red-bg:hover,
.ticket_list_item .red-bg:hover:active,
.ticket_list_item .red-bg:hover:focus {
  color: #5f1a89 !important;
}

.movie-header__call-to-action-container {
  background-image: url(https://www.foxmovies.com/s3/the-new-mutants/nm-cta-bar-1234.jpg);
  background-size: cover;
  padding: 0px !important;
}

.movie-header__call-to-action-container {
  background: #000;
}

.movie-header__call-to-action-container {
  height: auto;
}

.movie-header__cta-wrapper {
  width: 100%;
  align-items: center;
  background: inherit;
  /*display: block;*/
  justify-content: center;
}

.movie-header__cta-button {
  position: relative;
  margin: 1.5% 1.5% 0% 1.5%;
  text-align: center;
  color: #000;
}

/* Carousel dot design */
.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 35px;
  height: 5px;
  margin-right: 4px;
  margin-left: 4px;
  text-indent: -999px;
  background-color: #ffffff;
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 45px;
  height: 8px;
  margin-right: 4px;
  margin-left: 4px;
  text-indent: -999px;
  background-color: #5f1a89;
}

/* slider nav controll */
.category-movie-items .owl-nav {
  position: absolute;
  right: 0;
  bottom: 63%;
  top: auto;
  width: 100%;
}

.category-movie-items .owl-nav .owl-prev {
  left: -60px;
  top: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 50px;
  height: 80px;
  border-radius: 4px 0px 0px 4px;
  border: 0 none;
  line-height: 72px;
  padding-left: 12px;
}

.category-movie-items .owl-nav .owl-next {
  right: -60px;
  top: -45%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px 0px 0px 4px;
  width: 50px;
  height: 80px;
  border: 0 none;
  line-height: 80px;
}

.category-movie-items .flaticon-send:hover,
.category-movie-items .owl-prev .flaticon-send,
.category-movie-items .owl-next .flaticon-send {
  color: #5f1a89;
}

.category-movie-items .flaticon-send:after,
.category-movie-items .flaticon-send:before {
  font-size: 18px;
}

.category-slide:hover .owl-nav .owl-prev {
  left: 0;
}

.category-slide:hover .owl-nav .owl-next {
  right: 0;
}

.mvcast-item li p {
  font-size: 12px;
  color: #ffffff;
}

.gallery-contetn {
  height: 150px;
  width: 230px;
}

/*.bs-slider {*/
/*max-height: 450px;*/
/*}*/

.cat-menu {
  margin-top: 0px;
}

.hadding-with-bg {
  /* padding-top: 10px;
    padding-bottom: 10px; */
}
.view-all-movies-1 {
  /* margin-top: 20px; */
}
.d-table {
  display: flex;
  width: 100% !important;
}
.d-table .col-md-3 {
  flex: 1;
}

.ticket_list_item_tabs {
  height: 100%;
}

.d-table .col-md-6 {
  flex: 1;
}
.ticket_list_item {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .category-slide .movie-item-contents {
    height: 255px;
    /*overflow: hidden;*/
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }

  .notfound .notfound-404 h1 {
    font-size: 122px;
  }

  .category-slide .movie-item-contents {
    height: 279px;
  }
  .d-table {
    display: block;
    width: 100% !important;
  }
}

.cat-menu ul li {
  margin-right: 15px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.search-movie-image img {
  width: 60px !important;
  height: 80px !important;
}
.search-result-box {
  z-index: 99999 !important;
}
.each_search_list {
  display: flex !important;
  align-items: center !important;
}

@media (max-width: 425px) {
  .hadding-area h2 small {
    left: 0 !important;
    float: left !important;
  }
  .seat-types {
    float: left !important;
    margin-top: 20px;
  }
  .search-movie-image img {
    width: auto !important;
    height: 80px !important;
  }
  .search-result-box {
    z-index: 99999 !important;
  }
  .each_search_list {
    display: flex !important;
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .seat-types {
    float: left !important;
    margin-top: 20px !important;
  }
}

html {
  scroll-behavior: smooth;
}

.tab-content .tab_elements {
  display: none;
}

.tab-content .tab_elements.current {
  display: block;
}
.cat-menu ul li a {
  padding: 0px 5px 0px;
  position: relative;
}
.cat-menu ul li.current a:hover,
.cat-menu ul li.current a span,
.cat-menu ul li.current a span:hover {
  color: #fff;
}
.cat-menu ul li.current span:after {
  height: 3px;
  bottom: -5px;
  width: 100%;
  background: #fff;
}
:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

.owl-carousel .owl-stage {
  margin: auto;
}

.widget-content {
  background-color: #070e19 !important;
}

.notice-app {
  background-color: #060f1a !important;
}
.special-widget p {
  color: #fff !important;
}

.modal-video-close-btn {
  left: 0 !important;
}

@media only screen and (min-width: 1900px) {
  .slider-contents {
    min-height: 640px !important;
  }
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
  margin-top: -4px;
}

.d-none {
  display: none !important;
}

.student-reward-submit-btn {
  color: #ffffff !important;
}
.student-reward-submit-btn:hover {
  color: #000000 !important;
}

.notice {
  background: rgb(215 0 0);
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  padding: 6px 16px 6px 16px;
  width: fit-content;
  margin-top: 10px;
}

.notice img {
  width: 20px;
  position: relative;
  margin-right: 10px;
  height: 20px;
}
