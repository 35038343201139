.show-time-wrapper {
    display: block;
    text-align: center
}

.show-time-wrapper .selebritie-contetn {
    text-align: left
}

.show-time-wrapper .selebritie-contetn>a {
    font-size: 24px;
    font-weight: 500
}

.show-time-wrapper .btn.btn-button,
.show-time-wrapper a.btn.btn-button {
    padding: 5px 10px;
    font-size: 16px
}

h5.showtime-heading {
    font-size: 15px
}

.show-time-wrapper .row.display-flex {
    display: flex;
    flex-wrap: wrap
}

.show-time-wrapper .row.display-flex>[class*=col-] {
    display: flex;
    flex-direction: column
}

.show-time-wrapper.odd,
.show-time-wrapper.odd .row {
    background-color: #252629
}

.show-time-wrapper.odd .time-select .time-select__item_6:before,
.time-select .time-select__item:before {
    background: #252629
}

.show-time-wrapper.odd .time-select .time-select__item_6:after,
.time-select .time-select__item:after {
    background: #252629
}

.show-time-wrapper.odd .time-select .time-select__item:before,
.time-select .time-select__item:before {
    background: #252629
}

.show-time-wrapper.odd .time-select .time-select__item:after,
.time-select .time-select__item:after {
    background: #252629
}

.show-time-wrapper.even .row {
    background-color: #000
}

.show-time-wrapper.even .time-select .time-select__item_6:before,
.time-select .time-select__item:before {
    background: #000
}

.show-time-wrapper.even .time-select .time-select__item_6:after,
.time-select .time-select__item:after {
    background: #000
}

.show-time-wrapper.even .time-select .time-select__item:before,
.time-select .time-select__item:before {
    background: #000
}

.show-time-wrapper.even .time-select .time-select__item:after,
.time-select .time-select__item:after {
    background: #000
}

.show-time-wrapper .row {
    border-bottom: 1px solid #39353c
}

.show-time-wrapper .col-md-4 {
    padding: 0 0
}

.show-time-wrapper .col-md-1,
.show-time-wrapper .col-md-10,
.show-time-wrapper .col-md-11,
.show-time-wrapper .col-md-12,
.show-time-wrapper .col-md-2,
.show-time-wrapper .col-md-3,
.show-time-wrapper .col-md-4,
.show-time-wrapper .col-md-5,
.show-time-wrapper .col-md-6,
.show-time-wrapper .col-md-7,
.show-time-wrapper .col-md-8,
.show-time-wrapper .col-md-9 {
    padding: 0 0;
    border-left: 1px solid #39353c;
    background-color: #252629
}



@media screen and (max-width:1024px) {
    .show-time-wrapper .col-md-1 {
        min-height: auto
    }
    .show-time-wrapper .row.display-flex>[class*=col-] {
        display: flex;
        flex-direction: column;
      
    }
}

@media screen and (max-width:991px) {
   
    .show-time-wrapper .row.display-flex>[class*=col-] {
        display: flex !important;
        flex-direction: column;
      
    }
}


.show-time-wrapper .col-md-1:nth-child(10) {
    border-left: 0
}

.show-time-wrapper .col-md-1:nth-child(9) {
    border-right: 1px solid #39353c
}

.show-time-wrapper .time-select .items-wrap {
    border: 0;
    margin: 30px 0
}

.showtime-heading {
    background-color: #311d3e;
    color: #fff;
    height: 100px;
    padding: 30px 0
}

.time-duration {
    font-size: 16px;
    font-style: italic;
    margin-top: 20px;
    color: #ffd564
}

.view-all-movies-1 {
    margin-top: 20px;
}

@media screen and (max-width:640px) {
    .view-all-movies-1 {
        margin: 0
    }
}