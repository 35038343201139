@font-face {
    font-family: Flaticon;
    /*src: url(../fonts/Flaticon.eot.html);*/
    src: url(../fonts/Flaticon.woff)format("woff");
    /*src: url(../fonts/Flaticon.eot-.html#iefix) format("embedded-opentype"), url(../fonts/Flaticon.woff) format("woff"), url(../fonts/Flaticon.ttf) format("truetype"), url(../fonts/Flaticon.svg.html#Flaticon) format("svg");*/
    font-weight: 400;
    font-style: normal
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: Flaticon;
        /*src: url(Flaticon.svg.html#Flaticon) format("svg")*/
    }
}

[class*=" flaticon-"]:after,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class^=flaticon-]:before {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 0
}

.flaticon-play-button:before {
    content: "\f100"
}

.flaticon-send:before {
    content: "\f101";
    font-size: 13px;
    text-align: center;
    padding: 4px
}

.flat-icons span.flaticon-play-button:before {
    font-size: 50px
}