@media (min-width: 1200px) {
  .container {
    width: 1060px;
  }  
  nav.navbar.bootsnav ul.nav > li > a{
    padding: 4px 8px !important;
    font-size: 12px !important;
  }
}

@media (min-width: 1460px){
  .container {
    width: 1380px;
  } 
  nav.navbar.bootsnav ul.nav > li > a{
    padding: 4px 10px !important;
  }
}

@media (max-width: 1083px){
  nav.navbar.bootsnav ul.nav > li > a{
      padding: 4px 5px !important;
      font-size: 10px !important;
  } 
}

@media (max-width: 991px){
  .navbar-toggle{
    display: block !important;
  }
}



a {
  color: #5f1a89;
}
.header-center {
  padding: 5px 0px;
}
.header-logo img {
  width: 120px;
}
nav.navbar.bootsnav ul.nav > li > a {
  padding: 4px 5px;
  margin: 0 1px;
  font-size: 11px;
}
.header-search button {
  position: absolute;
  right: 0;
  top: 0;
  background: #5f1a89;
  border: none;
  padding: 4px 28px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
}
.header-search .header-search-input {
  width: 0%;
}
.header-search .header-search-input,
.header-search .header-search-input.search-open {
  padding: 15px 20px;
  font-size: 12px;
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.4);
  height: 32px;
}
.header-search .header-search-input.search-open {
  width: 100%;
}
.social .attr-nav {
  width: 160px;
}
.mvcast-item li {
  display: inline-block;
  text-align: center;
  padding-right: 10px;
}
.mvcast-item li:last-child {
  padding-right: 0px;
}
.mvcast-item li img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.mvcast-item li p {
  font-size: 12px;
}
.category-slide2 .owl-prev {
  right: 44px;
  top: -52px;
}
.category-slide2 .owl-next {
  top: -52px;
  right: 0px;
}
.cat-menu ul li span:after {
  height: 3px;
  bottom: -5px;
}
.cat-menu .nav-tabs>li.active>a,
.cat-menu .nav-tabs>li.active>a:focus,
.cat-menu .nav-tabs>li.active>a:hover {
  border: 1px solid transparent;
}
.full-withbanner {
  margin-bottom: 30px;
}
.ratting {
  color: #fff;
}
.show-time-wrapper .time-select .items-wrap {
  margin: 10px 0;
}
h5.showtime-heading,
.dec-review-meta ul li a,
.dec-review-meta ul li span,
.time-duration {
  font-size: 13px;
  line-height: 18px;
}
.special-widget {
  border: 1px solid #5f1a89;
  /*background: #fff;*/
}
.special-widget p {
  color: #5f1a89;
  font-size: 13px;
}
.list-group-item,
.bs-example .checkbox-inline,
.bs-example .radio-inline,
.widget-content p {
  font-size: 13px;
  line-height: 20px;
}
.bs-example .checkbox-inline, .bs-example .radio-inline {
  margin: 10px 2px 10px 0px;
}
.special-widget-title {
  background: #5f1a89;
}
.details-content,
.products.product-list {
  width: 100%;
  float: left;
  margin-bottom: 60px;
}
.hadding-area h2 {
  display: inline;
}
.show-time-wrapper {
  margin-bottom: 30px;
}
.show-time-wrapper:last-child {
  margin-bottom: 0;
}
.styled-select {
  background: #fff;
  height: 30px;
  line-height: 30px;
}
.styled-select select {
  color: #5f1a89;
  font-size: 13px;
  text-transform: uppercase;
  width: 160px;
}
.styled-select select option {
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styled-select#lang-selector:after {
  color: #5f1a89;
  content: "\ef79";
  font-family: "IcoFont";
}
.show-time-wrapper .btn.btn-button, .show-time-wrapper a.btn.btn-button {
  font-size: 12px;
  margin-top: 20px;
}
.showtime-heading {
  background-color: #3e3e40;
}
.selebritie-contetn .details-buttons {
  margin-top: 0;
}
.show-time-wrapper.even .time-select .time-select__item:after,
.time-select .time-select__item:after,
.show-time-wrapper.even .time-select .time-select__item:before,
.time-select .time-select__item:before,
.show-time-wrapper.even .time-select .time-select__item_6:before,
.time-select .time-select__item:before,
.show-time-wrapper.even .time-select .time-select__item_6:after,
.time-select .time-select__item:after,
.show-time-wrapper.even .row {
  background-color: #252629;
}
.show-time-wrapper .selebritie-contetn > a:hover {
  color: #fff;
}
.time-select .time-select__item,
.time-select .time-select__item,
.time-select .time-select__item_6 {
  width: 86px;
}
nav.navbar.bootsnav ul.nav li.megamenu-fw>a:hover,
nav.navbar.bootsnav ul.nav > li.active > a,
nav.navbar.bootsnav ul.nav > li.active > a:hover,
nav.navbar.bootsnav ul.nav > li > a:hover,
button.btn.btn-button.btn-border,
a.btn.btn-button.btn-border,
.btn.btn-button, a.btn.btn-button,
.cat-menu ul li button, .header-search button {
  border-radius: 4px;
}
.show-time-wrapper .socila-tw,
.show-time-wrapper .socila-tw,
.show-time-wrapper .socila-sk,
.show-time-wrapper .socila-pin,
.show-time-wrapper .socila-ins,
.show-time-wrapper .socila-fb {
  color: #fff;
}
.show-time-wrapper .row {
  border: 0 none;
  margin-bottom: 0;
}
.selebritie-img img {
  padding: 30px 0 30px 0px;
}
.selebritie-contetn {
  color: #fff;
  padding-right: 0;
  padding: 30px 0 0px 20px;
}
.show-time-wrapper .selebritie-contetn > h3 {
  font-size: 20px;
  font-weight: 500;
}
.seat-types .btn {
  margin-bottom: 0px;
  padding: 5px 10px 1px;
}
.seat-types .btn:hover {
  color: #fff;
}
/* .time-select.time-select__group .btn.btn-button.red-bg.button-1 {
margin-left: -11px;
} */
.category-slide2 {
  margin-bottom: 50px;
}
.dec-review-meta ul li a:hover {
  color: #fff;
}
.wrap-sticky {
  height: auto;
}
.bs-slider {
  /*max-height: 600px;*/
}
.slider-contents {
  padding: 7% 0;
  min-height: 640px;
}
.view-all-movies-1 {
  /* margin-top: 42px; */
}
.pt-45 {
  padding-top: 45px;
}
.pb-55 {
  padding-bottom: 55px;
}
.inner-page {
  background: url(images/slider/home-3/wrap-bg.png) no-repeat 0% 0%;
  background-attachment: fixed;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.latest-news-section {
  background: url(images/slider/home-3/wrap-bg.png) no-repeat 0% 0%;
  background-attachment: fixed;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.category-movie {
  position: relative;
  overflow: hidden;
}
.category-movie:before {
  background-image: url(images/slider/home-3/slider111.jpg);
  background-position: top;
  background-size: cover;
  content: "";
  position: absolute;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.category-movie:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(95, 26, 137, 0.7);
  position: absolute;
  left: 0;
  top: 0;
}
.haddings {
  z-index: 9;
  position: relative;
}
.button-details {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.hover-right {
  margin-right: -106px;
}
.full-withbanner {
  background: url(images/banner.png) no-repeat 0% 0%;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  position: relative;
}
.full-withcon.pt-75 {
  padding-top: 65px;
}
.full-withbanner:before {
  background-image: url(images/slider/home-3/slider111.jpg);
  background-position: top;
  background-size: cover;
  content: "";
  position: absolute;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
.full-withbanner:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(95, 26, 137, 0.7);
  position: absolute;
  left: 0;
  top: 0;
}
.cat-menu {
  position: relative;
  z-index: 9;
}
.hadding-with-bg {
  border-left: 0 none;
  padding-left: 0;
  /* margin-bottom: 10px; */
}
.cat-menu ul li a {
  padding-left: 0;
}
.cat-menu ul li span:hover {
  color: #fff;
}
.cat-menu ul li span:hover:after {
  background: #fff;
}
.copyright {
  background: #5f1a89;
}
.copyright p {
  color: rgba(202, 202, 202, 0.7);
}
.copyright a {
  color: #fff;
}
.hadding-area a.view-all-news-1 {
  margin-bottom: 0px!important;
  margin-top: 0px;
}
.ft-content p {
  padding: 0 0 10px;
}
.ft-content .social-link {
  padding-top: 15px;
}
.widget-event-post .img {
  width: 130px;
  padding: 0 20px 0 0;
}
.widget-event-post .img img {
  width: 100%;
}
.widget-event-post .item .single-item:last-child img {
  margin-bottom: 20px;
}
.widget-event-post .item {
  padding: 0px 20px 20px;
}
.widget-event-post .item a.btn {
  margin-top: 20px;
  margin-bottom: 0
}
/* .latest-news-small .movie-item-contents img {
  height: 167px;
} */
.latest-news .movie-item-contents small {
  color: #fff;
}
.latest-news .movie-item-contents h3 {
  color: #fff;
  font-size: 25px;
  display: inline-block;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
}
.latest-news .latest-news-small .movie-item-contents h3{
  font-size: 16px;
  line-height: 22px;
}
.gradient:hover a > img,
.gradient2:hover a > img,
.gradient3:hover a > img,
.gradient5:hover a > img {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}
.widget-recent-post .item {
  background: #070e19;
}
.comment-area {
  background: transparent;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
.details-slider-content {
  background-color: rgba(0,0,0,.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 20px 25px;
  color: #fff;
  text-align: left;
}
.details-page .owl-theme .owl-dots .owl-dot span {
  border: 1px solid #fff;
  background: transparent
}
.details-page .owl-theme .owl-dots .owl-dot.active span {
  background: #5f1a89;
}
.details-page .owl-controls .owl-dots{
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.ticket_list img {
  width: 190px;
}
.ticket_list h4 {
  margin: 20px 0px;
  font-size: 16px;
  font-weight: 400;
}
.ticket_list a.btn.btn-button {
  margin-bottom: 0;
}
.ticket_list a.btn.btn-button:hover {
  color: #5F1A88;
}
.ticket_list_item {
  border: 2px solid #5F1A88;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
}
.contact-hadding-area {
  margin-bottom: 0;
  margin-top: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
}
/* .contact-hadding-area h3 {
  margin: 0;
} */
.widget-content.fav-food h4 {
  font-weight: 400;
  margin: 10px 0px;
  font-size: 16px;
  color: #5f1a89;
}
.widget-content.fav-food a {
  margin-bottom: 0;
}
.widget-content.fav-food input[type="text"] {
  margin-bottom: 12px;
}
.media_list {
  margin-top: 20px;
}
.media_list li {
  line-height: normal;
  padding: 15px 0px;
}
.media_list li .media-text-body h3 {
  font-size: 18px;
  margin-bottom: 12px;
}
.media_list li .media-text-body p {
  font-size: 14px;
  line-height: 24px;
}
.media_list li .media-text-body p span.check-tx {
  color: #5F1A88;
}
.sticky_inner {
  padding: 0px 15px;
  left: auto!important;
}
.location_modal .modal-title {
  font-size: 22px;
}
.location_modal .modal-content {
  padding: 40px 50px;
}
.location_modal .modal-content ul {
  width: 80%;
  margin: 30px auto 0;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.location_modal .modal-content ul li {
  margin-bottom: 15px;
}
.location_modal .modal-content ul li:last-child {
  margin-bottom: 0px;
}
.location_list ul li a,
.location_list ul li button {
  padding: 10px 16px 10px 60px;
  color: #5F1A88;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  background: #fff;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  width: 100%;
  position: relative;
  transition: all 500ms;
}
.location_list ul li a p,
.location_list ul li button p {
  font-size: 12px;
  line-height: 21px;
  margin-top: 2px;
  font-weight: 400;
  color: #808080;
  transition: all 500ms;
}
.location_list ul li a:hover,
.location_list ul li button:hover {
  border: 1px solid #5f1a89;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.18);
  transition: all 500ms;
}
.location_list ul li a:before,
.location_list ul li button:before {
  content: "\ef79";
  font-family: "IcoFont";
  position: absolute;
  left: 20px;
  right: 0;
  top: 13px;
  margin: 0 auto;
  font-size: 20px;
  color: #000;
  transition: all 500ms;
}
.location_modal .modal-header .close {
  opacity: 1;
  position: absolute;
  top: 12px;
  right: 14px;
  margin: 0;
}
.location-modal-body {
  padding: 0px;
}
.modal-header {
  padding: 0px;
  border: 0 none;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 700px;
    margin: 30px auto;
  }
}
@media (max-width: 1450px){
  .slider-contents {
    padding: 5% 0;
    min-height: 700px;
  }
}
@media (max-width: 1024px) and (orientation: landscape) {
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 6px 12px;
  }
}
/* Final Fixing */
/* Final Fixing */
/* Final Fixing */
/* Final Fixing */
html, body {
  font-family: 'Heebo', sans-serif!important;
}
.movie-item-title {
  margin-bottom: -38px;
  overflow: hidden;
  opacity: 0
}
.movie-item-content:hover .movie-item-title {
  margin-bottom: 0;
  opacity: 1;
  transition: all .4s ease-in-out
}
/*.bs-slider {*/
  /*max-height: 400px;*/
/*}*/
.slider-content > h2 {
  font-weight: 500;
  font-size: 35px;
}
.slider-content > p {
  line-height: 28px;
  font-size: 16px;
}
.slider-contents::before {
  background: none;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) , rgba(0, 0, 0, .2));
}
.cat-menu ul li a {
  font-size: 22px;
  font-weight: 700;
}
.cat-menu ul li.active span:after {
  height: 3px;
  bottom: -5px;
}
.cat-menu {
  margin-top: 12px;
  margin-bottom: 0px;
}
.hadding-area h2 {
  font-size: 26px;
  font-weight: 500;
}
button.btn.btn-button.btn-border,
a.btn.btn-button.btn-border,
.btn.btn-button,
a.btn.btn-button {
  font-weight: 400;
  padding: 6px 18px;
  font-size: 13px;
  /* margin-bottom: 15px !important; */
}
/* .inner-page .hadding-area {
  padding-bottom: 0px;
} */
.seat-types {
  margin-top: 0;
}
.view-all-movies-1 {
  /* margin-top: 32px; */
}
.ft-widget h2 span {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 6px;
}
.latest-news-small .movie-item-title a,
.movie-item-title > a {
  letter-spacing: 0px;
  font-size: 14px;
  line-height: 22px;
}
.movie-item-content:hover .item-cat-hover {
  padding-top: 0px;
}
.item-cat-hover ul li a,
.item-cat-hover ul li span {
  font-size: 12px;
}
.movie-item-content-buttom {
  padding-bottom: 0;
}
.movie-item-content-center {
  top: 20%;
}
.ft-content > ul li a {
  line-height: 28px;
  font-size: 13px;
}
.widget-title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
}
.location_list ul li button:before,
.location_list ul li a:before,
.styled-select#lang-selector:after {
  color: #5f1a89;
  content: "\f041";
  font-family: "FontAwesome";
  right: 13px;
}
h5.showtime-heading {
  font-weight: 400;
}
.location_pick_modal {
  border: 0 none;
  border-right: 1px solid #9a9ea1;
  background: #fff;
  font-size: 11px;
}
.location_modal .modal-content .ticket_list ul {
  max-height: 100%;
}
.ticket_list_item,
.location_modal .modal-content .ticket_list ul li {
  margin-bottom: 0px;
}
/*.details-big-img {*/
  /*height: 400px;*/
/*}*/
.details-big-img:hover img {
  transform: scale(1);
}
.details-big-img img {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
h4,
h2.title,
.details-title > h2 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
}
.details-slider-content h4 {
  font-size: 18px;
  font-weight: 400;
}
.details-title > h2 {
  font-size: 19px;
}
.details-reviews {
  margin-top: -33%;
}
.details-big-img:after {
  background: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) , rgba(0, 0, 0, 0));
}
.media_list li .media-text-body h3 {
  font-size: 22px;
}
.swal-footer {
  text-align: center;
}
.swal-footer .swal-button {
  background-color: #5f1a89;
  border: 2px solid #5f1a89;
}
.category-movie-items .owl-nav {
  position: absolute;
  right: 0;
  bottom: 63%;
  top: auto;
  width: 100%;
}
.category-movie-items .owl-controls .owl-nav .owl-prev {
  left: -60px;
  top: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0;
  width: 50px;
  height: 80px;
  border-radius: 4px 0px 0px 4px;
  border: 0 none;
  line-height: 72px;
  padding-left: 12px;
}
.category-movie-items .owl-controls .owl-nav .owl-next {
  right: -60px;
  top: -45%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px 0px 0px 4px;
  width: 50px;
  height: 80px;
  border: 0 none;
  line-height: 80px;
}
.category-movie-items .flaticon-send:hover,
.category-movie-items .owl-prev .flaticon-send,
.category-movie-items .owl-next .flaticon-send {
  color: #5f1a89;
}
.category-movie-items .flaticon-send:after,
.category-movie-items .flaticon-send:before {
  font-size: 18px;
}
.category-slide:hover .owl-controls .owl-nav .owl-prev {
  left: 0;
}
.category-slide:hover .owl-controls .owl-nav .owl-next {
  right: 0;
}
@media (max-width: 1080px) {
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 5px 5px;
    font-size: 11px;
  }
  .slider-content > h2 {
    font-size: 36px;
  }
  .slider-content > p {
    padding-left: 0px;
  }
}
@media (max-width: 980px) {
  button.btn.btn-button.btn-border,
  a.btn.btn-button.btn-border,
  .btn.btn-button,
  a.btn.btn-button {
    padding: 6px 14px;
  }
  .navbar-toggle i:before {
    content: "\f0c9";
  }
  #navbar-menu {
    z-index: 999;
    position: relative;
    margin-top: -5px;
  }
  .wrap-sticky nav.navbar.bootsnav {
    z-index: 99;
  }
  nav.navbar.bootsnav .navbar-nav>li>a {
    border-bottom: solid 1px rgba(224, 224, 224, 0.25);
    border-top: 0 none;
  }
  nav.navbar.bootsnav ul.nav > li > a {
    padding: 10px 15px;
    font-size: 14px;
  }
  nav.navbar.bootsnav ul.nav li.megamenu-fw>a:hover,
  nav.navbar.bootsnav ul.nav > li.active > a,
  nav.navbar.bootsnav ul.nav > li.active > a:hover,
  nav.navbar.bootsnav ul.nav > li > a:hover,
  button.btn.btn-button.btn-border,
  a.btn.btn-button.btn-border,
  .btn.btn-button, a.btn.btn-button,
  .cat-menu ul li button,
  .header-search button {
    border-radius: 4px;
  }
  .header-search .header-search-input.search-open {
    border-color: #fff;
  }
  .details-big-img {
    height: auto;
  }
  .header-search {
    margin: -5px 10px 0px 0px;
  }
  .header-search button {
    top: -10px;
    right: -10px;
    /* background: none; */
    padding: 4px 8px;
  }
  .header-search button span {
    font-size: 16px;
  }
  .header-search button:hover {
    /* background: none; */
    /* color: #fff; */
  }
  .header-search .header-search-input{
    width: 0px;
    position: absolute;
    right: -6px;
    top: -10px;
    background: #000;
    padding: 0px;
  }
  /* .header-search .header-search-input.search-open {
    width: 100%;
    position: relative;
    right: -10px;
    top: -9px;
    background: #000;
  } */
  .navbar-toggle {
    float: left;
    padding: 0;
    margin-top: 20px;
    display: block;
  }
  .navbar-toggle i {
    color: #fff;
    font-size: 24px;
  }
  .time-select.time-select__group .btn.btn-button.red-bg.button-1 {
    /* margin-left: 12px; */
    margin-top: 12px;
  }
  .cat-menu ul li {
    margin-right: 0;
  }
  .cat-menu ul li a span {
    font-size: 26px;
    font-weight: 700;
  }
  .slider-contents {
    padding: 9% 0;
  }
  .slider-contents::before {
    min-height: 100vh;
  }
  .slider-content > h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .slider-content > p {
    display: none;
  }
  .social .attr-nav {
    width: 96%;
    margin: -5px 10px 0px 0px;
    right: 0;
    float: none;
    display: initial;
  }
  .social .attr-nav .styled-select {
    background: none;
    height: 40px;
    line-height: 40px;
    position: relative;
  }
  .social .attr-nav .styled-select select {
    color: #fff;
    width: 100%;
  }
  .styled-select#lang-selector:after {
    color: #5f1a89;
  }
  .social .attr-nav .styled-select {
    background: #fff;
  }
  .social .attr-nav .styled-select button {
    width: 100%;
    background: #fff;
  }
  .slider-contents {
    min-height: 300px;
  }
  .bs-slider {
    max-height: 300px;
  }
  .latest-news-small .movie-item-contents img {
    height: auto;
  }
  .footer-bg {
    padding-top: 0px;
  }
  .location_modal .modal-content ul {
    width: 100%;
  }
  .latest-news-small {
    margin-top: 0px;
  }
}
@media (max-width: 640px) {
  .navbar-toggle i.fa-bars:before {
    content: "\f0c9";
  }
  .navbar-toggle i.fa-times:before {
    content: "\f00d";
  }
  .details-big-img {
    height: auto;
  }
  /* .dec-review-img img {
    width: 34%;
  } */
  .details-reviews {
    margin-top: -20%;
  }
  .dec-review-dec {
    margin-top: 6%;
  }
  .slider-contents {
    padding: 4% 0;
  }
  .slider-content > h2 {
    margin-bottom: 0px;
  }
  .copyright p{
    text-align: center!important;
  }
  .location_modal .modal-content .ticket_list ul li {
    width: 50%;
    float: left;
  }
  .movie-item-title {
    text-overflow: initial;
    white-space: initial;
  }
  .cat-menu ul li {
    margin-right: 20px;
  }
  .category-movie {
    padding-bottom: 0px;
  }
  .cat-menu {
    margin-top: 6px;
  }
}
@media (max-width: 480px) {
  .location_modal .modal-content {
    padding: 20px 10px;
  }
  .location_modal .modal-content ul {
    width: 100%;
    margin: 20px auto 0;
  }
  .category-movie {
    padding-top: 0px;
  }
  /* .header-search button {
    background: none;
    padding: 0;
  } */
  .header-search button:hover {
    /* background: none; */
    /* color: #fff;
    top:-5px; */
  }
  .header-search .header-search-input{
    width: 0px;
    position: absolute;
    right: -10px;
    top: -9px;
    background: #000;
    padding: 0px;
  }
  .header-search .header-search-input.search-open {
    width: 233px;
    position: absolute;
    right: -10px;
    top: -10px;
    background: #000;
  }
  .hadding-with-bg {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .view-all-movies-1 {
    margin-top: 0;
    font-size: 12px!important;
  }
  .cat-menu ul li {
    margin-right: 0;
    margin-bottom: 8px;
  }
  nav.navbar.bootsnav .navbar-collapse.collapsing .navbar-nav,
  nav.navbar.bootsnav .navbar-collapse.collapse .navbar-nav {
    background: #000;
    padding-right: 0;
  }
  nav.navbar.bootsnav {
    padding: 0px 0 10px;
  }
  nav.navbar.bootsnav .navbar-nav>li>a {
    border-top: solid 1px #272727;
    border-bottom: 0 none;
  }
  .wrap-sticky nav.navbar.bootsnav {
    z-index: 999;
  }
  .wrap-sticky nav.navbar.bootsnav.sticked {
    position: relative;
  }
  .cat-menu ul li a span,
  .hadding-area h2 {
    display: inline;
    font-size: 22px;
    font-weight: 500;
  }
  .cat-menu ul li a span {
    display: inline;
    font-size: 20px;
    font-weight: 500;
  }
  .hadding-area h2 {
    line-height: 40px;
  }
  .cat-menu ul li.active span:after {
    height: 4px;
    bottom: -6px;
  }
  nav.navbar.bootsnav ul.nav > li > a {
    margin-bottom: 0;
    background: #000;
  }
  .slider-content > h2 {
    font-size: 22px;
    padding: 0;
    margin-bottom: 10px;
  }
  .footer-bg {
    padding-top: 0;
  }
  .item-cat-hover,
  .movie-item-title {
    padding-left: 12px;
  }
  .movie-item-title > a {
    font-size: 17px;
  }
  .btn.btn-button, a.btn.btn-button,
  button.btn.btn-button.btn-border,
  a.btn.btn-button.btn-border {
    font-size: 14px;
    z-index: 997;
    position: relative;
  }
  .btn.btn-button.white-outline {
    margin-bottom: 0;
  }
  .cat-menu ul li a {
    margin-right: 20px;
  }
  /* nav.navbar.bootsnav .navbar-collapse.collapse.in,
  nav.navbar.bootsnav .navbar-collapse.collapse.in .navbar-nav {
  padding: 0;
  color: #000;a
  } */
  .location_modal .modal-content .ticket_list ul li {
    width: 100%;
    float: none;
  }
}
@media (max-width: 480px) {
  .slider-content > h2 {
    font-size: 16px;
    padding: 0;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .social .attr-nav .styled-select {
    background: #fff;
  }
  .social .attr-nav .styled-select button {
    width: 100%;
    background: #fff;
  }
}

.wrap-sticky {
  height: auto!important;
}
.header-section .wrap-sticky nav.navbar.bootsnav {
  position: relative;
}
